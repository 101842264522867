
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body{
  font-family: 'Montserrat', sans-serif;
background-color: rgb(243 244 246 );
}

h1 {
  font-family: "Onest", sans-serif;
}


/* navbar.css */
.dropdown-menu {
  border: none; 
}


@media (min-width: 1024px) {
  .dropdown-menu {
    border: 1px solid #ccc; 
    border-radius: 8px; 
    padding-right: 50px; 
    display: flex;
    align-items: left;
    text-align:left; 
    margin-top: 80px;
  }
  .dropdown-menu {
    margin-top: 80px;
  }
  
}
